import React from "react";
import Layout from "../layouts/index";
import { FaArrowCircleLeft } from "react-icons/fa";

import { graphql, StaticQuery } from "gatsby";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

const NotFoundPage = (props) => {
  return (
    <Layout path={props.location.pathname}>
      <div className='container mx-auto'>
        <div className='row m-0 mt-5'>
          <div className='col-md-7'>
            <h1 className='mt-3'>404</h1>
            <h4>
              <FormattedMessage id='not_found' />
            </h4>
            <p className='lead'>
              <FormattedMessage id='not_found_i' />
            </p>
            <p className='mt-2'>
              <Link to='/'>
                <FaArrowCircleLeft />
                <span className='ml-1 lead'>
                  <FormattedMessage id='Back_to_home' />
                </span>
              </Link>
            </p>
          </div>
          <div className='col-md-4'>
            <img src='/404.webp' width='100%' alt='404' title='404' />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
